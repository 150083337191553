import React from 'react'
import PropTypes from 'prop-types'
import CustomImg from '../global/CustomImg'

import styles from './ChapterSlide.module.scss'

const ChapterSlide = ({
  chapterNumber,
  chapterImage,
  chapterTitle,
  chapterSlug,
  currentChapterIndex,
  // setSlideFunction,
  zoomOrigin,
  updateSlide,
}) => {
  return (
    <button
      className={`
        ${styles.chapter}
        ${chapterNumber === currentChapterIndex && styles.active}
      `}
      role="tab"
      aria-label={`${chapterTitle}`}
      aria-selected={chapterNumber === currentChapterIndex}
      aria-controls={`tabpanel-${chapterSlug}`}
      //onMouseEnter={() => setSlideFunction(chapterNumber)}
      // onClick={() => setSlideFunction(chapterNumber)}
      onClick={() => updateSlide(chapterNumber)}
      // id={`chapter-${chapterNumber}`}
    >
      <CustomImg
        localFile={chapterImage.localFile}
        alt={chapterImage.alternativeText}
        className={`${styles.chapterImg}`}
        imgStyle={{ objectPosition: `${zoomOrigin}%` }}
        loading={'eager'}
      />
    </button>
  )
}

ChapterSlide.propTypes = {
  chapterNumber: PropTypes.number.isRequired,
  chapterImage: PropTypes.object.isRequired,
  currentChapterIndex: PropTypes.number.isRequired,
  // setSlideFunction: PropTypes.func.isRequired,
  zoomOrigin: PropTypes.number.isRequired,
}

export default ChapterSlide
