import { navigate } from '@reach/router'
import { graphql } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import ChapterNavTitle from '../components/chapterNav/ChapterNavTitle'
import ChaptersCarousel from '../components/chapterNav/ChaptersCarousel'
import LoadingLogo from '../components/chapterNav/LoadingLogo'
import Layout from '../components/global/Layout'
import SEO from '../components/global/Seo'
import TopNav from '../components/global/TopNav'
//import DownArrow from '../components/chapterNav/DownArrow'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ChapterTitle from '../components/chapterNav/ChapterTitle'
import NextChapterLink from '../components/chapterNav/NextChapterLink'
import SectionsTimeline from '../components/chapterNav/SectionsTimeline'
import ArrowIcon from '../components/icons/ArrowIcon'
import styles from './index.module.scss'
gsap.registerPlugin(ScrollTrigger)

const ChapterMenuPage = ({ data, location }) => {
  let [currentIndex, setCurrentIndex] = useState(0)
  const chaptersData = data.allStrapiChapter.edges.map(({ node }) => node)

  // console.log(chaptersData)
  // Jump to the right slide based the url hash
  const URL_HASHES = [
    '#introduction',
    '#chapter-1',
    '#chapter-2',
    '#chapter-3',
    '#chapter-4',
    '#conclusion',
  ]

  const updateSlide = index => {
    if (index === 0) {
      navigate(`#introduction`)
    } else if (index === 5) {
      navigate(`#conclusion`)
    } else {
      navigate(`#chapter-${index}`)
    }
  }

  // useEffect(() => {
  //   URL_HASHES.forEach((hash, index) => {
  //     hash === location.hash && setCurrentIndex(index)
  //   })
  // }, [location])

  useEffect(() => {
    let hashSlug = ''
    let hashIndex = 0

    URL_HASHES.forEach((hash, index) => {
      if (hash === location.hash) {
        hashSlug = hash
        hashIndex = index
      }
    })
    hashSlug.length ? setCurrentIndex(hashIndex) : setCurrentIndex(0)
    // eslint-disable-next-line
  }, [location])

  // Setup Refs for animations
  const carouselRef = useRef(null)
  const chapterNavTitleRef = useRef(null)
  const downArrowRef = useRef(null)

  // Hide the Down Arrow on scroll
  useEffect(() => {
    const downArrowTl = gsap.timeline({
      scrollTrigger: {
        id: `carousel-container`,
        trigger: carouselRef.current,
        start: 'top top-=20%',
        toggleActions: 'play none none reverse',
      },
    })
    downArrowTl.to(downArrowRef.current, {
      opacity: 0,
      y: -20,
      duration: 0.5,
      ease: 'power1.inOut',
    })
    // Cleanup the timeline on unmount
    return () => {
      downArrowTl.kill()
    }
  }, [carouselRef])

  // Show the Chapter Title in the navbar on scroll
  useEffect(() => {
    const chapterNavTitleTl = gsap.timeline({
      scrollTrigger: {
        id: `carousel-container`,
        trigger: carouselRef.current,
        start: 'top top-=20%',
        toggleActions: 'play none none reverse',
      },
    })

    chapterNavTitleTl
      .to(chapterNavTitleRef.current, {
        height: 'auto',
        // y: -20,
        duration: 0.5,
        ease: 'power1.inOut',
      })
      .to(
        chapterNavTitleRef.current,
        {
          opacity: 1,
          delay: 0.5,
          duration: 0.5,
          ease: 'power1.inOut',
        },
        '-=0.5'
      )
    // Cleanup the timeline on unmount
    return () => {
      chapterNavTitleTl.kill()
    }
  }, [carouselRef])

  return (
    <Layout>
      <SEO title="Home" image={chaptersData[5].chapter_image} />
      <h1 className={`srOnly`}>
        Under One Flag Online Exhibition: Chapter Menu
      </h1>
      <TopNav>
        {/* Chapter Titles that appear in the header  */}
        <div
          ref={chapterNavTitleRef}
          className={`${styles.chapterNavTitleWrapper}`}
        >
          <ChapterNavTitle
            chapterNumber={chaptersData[currentIndex].subtitle}
            chapterTitle={chaptersData[currentIndex].title}
          />
        </div>
      </TopNav>
      <LoadingLogo />
      {/* Slider - Images and Arrows Only */}
      <div ref={carouselRef}>
        <ChaptersCarousel
          index={currentIndex}
          // setIndex={setCurrentIndex}
          updateSlide={updateSlide}
          chaptersData={chaptersData}
        />
      </div>
      <div className={`${styles.downArrowContainer}`} ref={downArrowRef}>
        <div className={`${styles.downArrow}`}>
          <ArrowIcon />
          {/* <DownArrow /> */}
        </div>
        <p className={`${styles.arrowText}`}>
          Scroll to Begin {chaptersData[currentIndex].subtitle}
        </p>
      </div>
      {/* Everything below the Slider. Chapter Titles, Sections etc, are conditionally rendered based on currentIndex */}
      {chaptersData.map(
        ({
          chapter_slug,
          chapter_number,
          title,
          subtitle,
          date,
          description,
          sections,
        }) =>
          chapter_number === currentIndex && (
            // Chapter Title, Descriptions etc
            <section className={`relPos`} key={chapter_number}>
              <ChapterTitle
                chapterNumber={subtitle}
                chapterTitle={title}
                chapterDate={date}
                chapterDescription={description}
                currentIndex={currentIndex}
              />

              {/* "Timeline" of Sections in a Chapter */}
              <SectionsTimeline
                sections={sections}
                chapterSlug={chapter_slug}
              />

              {/* <SectionsTimelineShadow /> */}
            </section>
          )
      )}
      {currentIndex < chaptersData.length - 1 ? (
        <NextChapterLink
          chapterNumber={chaptersData[currentIndex + 1].subtitle}
          chapterTitle={chaptersData[currentIndex + 1].title}
          updateSlide={updateSlide}
          nextIndex={currentIndex + 1}
        />
      ) : (
        <NextChapterLink
          chapterNumber={''}
          chapterTitle={''}
          updateSlide={updateSlide}
          nextIndex={currentIndex + 1}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiChapter(sort: { fields: chapter_number }) {
      edges {
        node {
          chapter_slug
          title
          subtitle
          description
          date
          chapter_number
          zoom_position
          chapter_image {
            alternativeText
            caption
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sections {
            section_date
            section_title
            section_desc
            section_code
            section_slug
            section_image {
              alternativeText
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default ChapterMenuPage
