import React from 'react'
import PropTypes from 'prop-types'
import RoundedArrow from '../buttons/RoundedArrow'
import ChapterSlide from './ChapterSlide'

import styles from './ChaptersCarousel.module.scss'

const ChaptersCarousel = ({ index, setIndex, chaptersData, updateSlide }) => {
  let isFirst = index === 0
  let isLast = index === chaptersData.length - 1
  const showNextSlide = () => {
    if (isLast) return
    // setIndex(index + 1)
    updateSlide(index + 1)
  }
  const showPreviousSlide = () => {
    if (isFirst) return
    // setIndex(index - 1)
    updateSlide(index - 1)
  }

  return (
    <div className={`${styles.chapterImgSlider} `} role="tablist">
      {chaptersData
        .map(
          ({
            chapter_number,
            chapter_image,
            title,
            chapter_slug,
            zoom_position,
          }) => (
            <ChapterSlide
              key={chapter_number}
              chapterNumber={chapter_number}
              chapterImage={chapter_image}
              chapterTitle={title}
              chapterSlug={chapter_slug}
              currentChapterIndex={index}
              // setSlideFunction={setIndex}
              updateSlide={updateSlide}
              zoomOrigin={zoom_position}
            />
          )
        )
        .sort((a, b) => a.key - b.key)}

      <div className={`${styles.sliderArrowsContainer}`}>
        <div className={`${styles.sliderArrow}`}>
          <RoundedArrow
            onClickFunction={showPreviousSlide}
            isHidden={isFirst}
            srLabel="Previous Chapter"
            direction="left"
          />
        </div>
        <div className={`${styles.sliderArrow}`}>
          <RoundedArrow
            onClickFunction={showNextSlide}
            isHidden={isLast}
            srLabel="Next Chapter"
            direction="right"
          />
        </div>
      </div>
    </div>
  )
}

ChaptersCarousel.propTypes = {
  index: PropTypes.number.isRequired,
  // setIndex: PropTypes.func.isRequired,
  chaptersData: PropTypes.array.isRequired,
}

export default ChaptersCarousel
