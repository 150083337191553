import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import SectionCard from './SectionCard'
import SectionDots from './SectionDots'
import styles from './SectionsTimeline.module.scss'
gsap.registerPlugin(ScrollTrigger)

const SectionsTimeline = ({ sections, chapterSlug }) => {
  // console.log(sections)

  // Sort the Sections by section_code
  const sectionsSorted = sections.sort((a, b) => {
    if (a.section_code < b.section_code) {
      return -1
    }
    if (a.section_code > b.section_code) {
      return 1
    }
    return 0
  })

  // Set the active Section Dot
  const [activeDot, setActiveDot] = useState(0)

  // Show the Section Dots on scroll
  const sectionsTimelineRef = useRef(null)
  const sectionDotsRef = useRef(null)

  // const [sectionDotsTl, setSectionDotsTl] = useState(null)
  useEffect(() => {
    const sectionDotsTl = gsap.timeline({
      scrollTrigger: {
        id: `sections-container`,
        trigger: sectionsTimelineRef.current,
        start: 'top center',
        toggleActions: 'play none none reverse',
      },
    })

    sectionDotsTl.to(sectionDotsRef.current, {
      opacity: 1,
      // y: -20,
      duration: 0.5,
      ease: 'power1.inOut',
    })
    return () => {
      sectionDotsTl.kill()
    }
  }, [sectionsTimelineRef])

  // useEffect(() => {
  //   // gsap.set(sectionDotsRef.current, { opacity: 0 }) // TODO: this isn't happening immediately. Move to css?
  //   sectionDotsTl &&
  //     sectionDotsTl.to(sectionDotsRef.current, {
  //       opacity: 1,
  //       // y: -20,
  //       duration: 0.5,
  //       ease: 'power1.inOut',
  //     })
  // }, [sectionDotsTl])

  // FIXME: this is probably the cause of rerenders in SectionCard.js. Can it use useRef instead of useState?
  // State to hold the Y positions of the text in SectionCard. Used by the dots to jump to the right section and place in the animation
  const [textPositions, setTextPositions] = useState([])

  const addToTextPositions = newTextPos => {
    if (newTextPos && !textPositions.includes(newTextPos)) {
      setTextPositions(textPositions => [...textPositions, newTextPos])
    }
  }

  return (
    <Fragment>
      <ul
        id={`tabpanel-${chapterSlug}`}
        className={`${styles.sectionsTimelineWrapper}`}
        ref={sectionsTimelineRef}
        aria-label="Chapter Sections"
      >
        {sectionsSorted.map(
          (
            {
              section_date,
              section_title,
              section_desc,
              section_image,
              section_slug,
              section_code,
            },
            index
          ) => (
            <SectionCard
              key={section_slug}
              sectionDate={section_date}
              sectionTitle={section_title}
              sectionDesc={section_desc}
              sectionImage={section_image}
              chapterSlug={chapterSlug}
              sectionSlug={section_slug}
              sectionCode={section_code}
              addToTextPositions={addToTextPositions}
              textPositions={textPositions}
              setActiveDot={setActiveDot}
              index={index}
            />
          )
        )}
      </ul>
      <div ref={sectionDotsRef} style={{ opacity: '0' }}>
        {sections.length > 1 && (
          <SectionDots
            sections={sectionsSorted}
            activeDot={activeDot}
            textPositions={textPositions}
          />
        )}
      </div>
    </Fragment>
  )
}

// TODO: Add proptypes

export default SectionsTimeline
