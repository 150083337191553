import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Halftone2 from '../backgrounds/Halftone2'
import styles from './ChapterTitle.module.scss'
gsap.registerPlugin(ScrollTrigger)

const ChapterTitle = ({
  chapterNumber,
  chapterTitle,
  chapterDate,
  chapterDescription,
  currentIndex,
}) => {
  const animElsRef = useRef([])

  const addToAnimElsRef = el => {
    if (el && !animElsRef.current.includes(el)) {
      animElsRef.current.push(el)
    }
  }

  // Animate in the text when the chapter changes
  useEffect(() => {
    const textTl = gsap.timeline()
    gsap.set(animElsRef.current, { y: '.5rem' })
    textTl.to(animElsRef.current, {
      opacity: 1,
      y: 0,
      duration: 1,
      stagger: 0.2,
      ease: 'power1.out',
    })
    // Cleanup the timeline on unmount
    return () => {
      textTl.kill()
    }
  }, [animElsRef, currentIndex])

  // Show the timeline start on scroll
  const lineRef = useRef(null)
  // const [lineTl, setLineTl] = useState(null)
  useEffect(() => {
    const lineTl = gsap.timeline({
      scrollTrigger: {
        id: `line`,
        trigger: lineRef.current,
        start: 'bottom bottom-=20%',
        toggleActions: 'play none none reverse',
      },
    })

    lineTl.to(lineRef.current, {
      opacity: 1,
      duration: 0.5,
      ease: 'power1.out',
    })
    // Cleanup the timeline on unmount
    return () => {
      lineTl.kill()
    }
  }, [lineRef, currentIndex])

  return (
    <div className={`sectionGrid1fr1fr ${styles.chapterTitleContainer}`}>
      <h2 style={{ opacity: 0 }} ref={addToAnimElsRef}>
        <span className={`textColorFuzzy h4 block`}>{chapterNumber}</span>
        <span className={`h3 block`}>{chapterTitle}</span>
      </h2>
      <div
        className={`${styles.chapterDescription}`}
        style={{ opacity: 0 }}
        ref={addToAnimElsRef}
      >
        <p className={`textColorFuzzy h4`}>{chapterDate}</p>
        <p className={`mt0`}>{chapterDescription}</p>
      </div>
      <div
        className={`${styles.timelineStart}`}
        style={{ opacity: 0 }}
        ref={lineRef}
      ></div>

      {/* Halftone Pattern  */}
      <div className={`${styles.halftoneWrapper}`}>
        <Halftone2 />
      </div>
    </div>
  )
}

ChapterTitle.propTypes = {
  chapterNumber: PropTypes.string.isRequired,
  // numberNames: PropTypes.array.isRequired,
  chapterTitle: PropTypes.string.isRequired,
}

export default ChapterTitle
