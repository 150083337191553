import React from 'react'
import PropTypes from 'prop-types'
import styles from './RoundedArrow.module.scss'

const RoundedArrow = ({ onClickFunction, isHidden, srLabel, direction }) => {
  const directionClass = (function (direction) {
    switch (direction) {
      case 'left':
        return styles.left
      case 'right':
        return styles.right
      case 'up':
        return styles.up
      case 'down':
        return styles.down
      default:
        return styles.right
    }
  })(direction)

  return (
    <button
      className={`${styles.roundedArrow} ${
        isHidden === true && styles.deactivate
      } 
      ${directionClass}
      `}
      onClick={onClickFunction}
      aria-label={srLabel}
    >
      {/* <p className={`srOnly`}>{srLabel}</p> */}
      <svg fill="none" viewBox="0 0 15 35">
        <path strokeWidth="4" d="M2 33l16-15.5L2 2" />
      </svg>
    </button>
  )
}

RoundedArrow.propTypes = {
  onClickFunction: PropTypes.func,
  isHidden: PropTypes.bool,
  srLabel: PropTypes.string,
}

RoundedArrow.defaultProps = {
  onClickFunction: function () {
    console.log('Arrow button was clicked!')
  },
  isHidden: false,
  srLabel: 'button',
}

export default RoundedArrow
