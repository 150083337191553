import PropTypes from 'prop-types'
import React from 'react'

import SectionDot from './SectionDot'
import styles from './SectionDots.module.scss'

let SectionDots = ({ sections, activeDot, textPositions }) => {
  return (
    <div className={`${styles.sectionDotsContainer}`}>
      <div className={`${styles.sectionDots}`}>
        <nav>
          <ul className={`${styles.sectionDotsLine}`}>
            {sections.map(
              ({ section_title, section_code, section_slug }, index) => (
                <SectionDot
                  sectionTitle={section_title}
                  sectionCode={section_code}
                  activeDot={activeDot}
                  textPositions={textPositions}
                  index={index}
                  key={index}
                />
              )
            )}
          </ul>
        </nav>
      </div>
    </div>
  )
}

SectionDots.propTypes = {
  sections: PropTypes.array.isRequired,
  // sectionNumber: PropTypes.string.isRequired,
  // sectionName: PropTypes.string.isRequired,
}

export default SectionDots
