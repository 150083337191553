import React from 'react'

import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import styles from './SectionDot.module.scss'
gsap.registerPlugin(ScrollToPlugin)

const SectionDot = ({
  sectionTitle,
  sectionCode,
  activeDot,
  textPositions,
  index,
}) => {
  // console.log(textPositions)
  // Add a namespace so that we don't have duplicate ids
  const prefix = `sec${sectionCode}`
  // console.log(textPositions)
  return (
    // eslint doesn't like that this has a click handler, but not a keydown or other attributes
    // but I don't think this actually needs keyboard navigation
    // eslint-disable-next-line
    <li
      className={`${styles.sectionDot} ${
        activeDot === index ? styles.activeDot : ''
      }`}
      onClick={() => {
        gsap.to(window, {
          duration: 1,
          // The textPositions stuff seems to work correctly in Production, but not Dev
          scrollTo: { y: textPositions[index] },
          // scrollTo: `#${prefix}-title`,
        })
        // console.log(textPositions[index])
        // console.log(document.body.offsetHeight)
      }}
    >
      <div className={`h5 ${styles.dotTitle}`}>{sectionTitle}</div>
    </li>
  )
}

export default SectionDot
