import PropTypes from 'prop-types'
import React from 'react'
import styles from './NextChapterLink.module.scss'

const NextChapterLink = ({
  chapterNumber,
  chapterTitle,
  updateSlide,
  nextIndex,
}) => {
  if (nextIndex < 6) {
    return (
      <div className={`${styles.nextChapter}`}>
        {/* <div className={`${styles.nextChapter}`}> */}
        <h3
          className={`${styles.chapterTitle}`}
          tabIndex="0"
          // eslint-disable-next-line
          role="link"
          onClick={() => {
            updateSlide(nextIndex)
            window.scrollTo({ top: 0, behavior: `auto` })
            // TODO: a11y: need to move focus somewhere else when this is clicked
          }}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              updateSlide(nextIndex)
              window.scrollTo({ top: 0, behavior: `auto` })
              // TODO: a11y: need to move focus somewhere else when this is clicked
            }
          }}
        >
          {/* <span className={` h4 block`}></span> */}
          <span className={`textColorFuzzy h4 block`}>
            Next: {chapterNumber}
          </span>
          <span className={`h3 block`}>{chapterTitle}</span>
        </h3>
        {/* </div> */}
      </div>
    )
  } else {
    return (
      <div
        className={`${styles.nextChapter}`}
        style={{ height: '12rem' }}
      ></div>
    )
  }
}

NextChapterLink.propTypes = {
  chapterNumber: PropTypes.string,
  chapterTitle: PropTypes.string,
  updateSlide: PropTypes.func,
  nextIndex: PropTypes.number.isRequired,
}

export default NextChapterLink
