import { Link } from 'gatsby'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import PropTypes from 'prop-types'
import React, { memo, useEffect, useRef, useState } from 'react'
import CustomImg from '../global/CustomImg'
import styles from './SectionCard.module.scss'
gsap.registerPlugin(ScrollToPlugin)

gsap.registerPlugin(ScrollTrigger)

// FIXME: Trying to use memo to prevent unneeded rerenders, but it's not working... something in the props is being updated
const SectionCard = ({
  sectionDate,
  sectionTitle,
  sectionImage,
  sectionDesc,
  sectionSlug,
  chapterSlug,
  sectionCode,
  addToTextPositions,
  textPositions,
  setActiveDot,
  index,
}) => {
  // console.log('sectioncard render')
  // console.log(textPositions)
  // Add a namespace so that we don't have duplicate ids
  const prefix = `sec${sectionCode}`

  // Ref to fix "Can't perform a React state update on an unmounted component." error
  const isMountedRef = useRef(null)

  // Create refs and state
  const sectionCardRef = useRef(null)
  const textRef = useRef(null)
  const animElements = useRef({})
  // const [inTl, setInTl] = useState(null)
  const inTl = useRef(null)
  // const [activeTl, setActiveTl] = useState(null)
  const activeTl = useRef(null)

  // Update Refs and Timeline when the component is mounted
  useEffect(() => {
    isMountedRef.current = true // Component is mounted

    // Add elements we want to animate to the animElement.current
    animElements.current = sectionCardRef.current && {
      date: sectionCardRef.current.querySelector(`#${prefix}-date`),
      title: sectionCardRef.current.querySelector(`#${prefix}-title`),
      description: sectionCardRef.current.querySelector(
        `#${prefix}-description`
      ),
      image: sectionCardRef.current.querySelector(`#${prefix}-image`),
    }

    // Setup the timelines

    inTl.current = gsap.timeline({
      scrollTrigger: {
        id: `in-container`,
        trigger: sectionCardRef.current,
        start: 'top bottom-=20%',
        end: 'bottom top-=120%', // the end marker is way off the top of the screen. Seemed to work best for timing
        onEnter: () => {
          isMountedRef.current && setActiveDot(index) // update the active dot when scrolling down
        },
        onLeaveBack: () => {
          isMountedRef.current &&
            (index > 0 ? setActiveDot(index - 1) : setActiveDot(0)) // update the active dot when scrolling up
        },
        toggleActions: 'play none none reverse',
        pinSpacing: false,
        scrub: 1,
        // markers: true,
      },
    })

    activeTl.current = gsap.timeline({
      scrollTrigger: {
        id: `${prefix}-trigger`,
        trigger: sectionCardRef.current,
        // start: 'center center',
        start: 'center center+=40px',
        // start: 'top top+=70px',
        // start: `${isPortrait ? 'top top+=138px' : 'center center+=56px'}`,
        toggleActions: 'play none none reverse',
        pin: sectionCardRef.current,
        anticipatePin: 1,
        scrub: 1, // scrub with momentum/smoothing
        // markers: true,
        // invalidateOnRefresh: true,
      },
    })

    return () => {
      isMountedRef.current = false // Flag the component as unmounted, so that it doesn't try to do any state updates
    }
    // eslint-disable-next-line
  }, [sectionCardRef, inTl, activeTl])

  // Set default values and add Tweens to the Timelines
  useEffect(() => {
    // Destructure the names of all the elements, as defined above
    const { date, title, description, image } =
      animElements.current !== null && animElements.current

    const textArray = [date, title, description]

    // All gsap.set() and tweens need to be reset when breakpoints change.
    // Mobile and Portrait Animations
    ScrollTrigger.matchMedia({
      '(max-aspect-ratio: 1/1)': function () {
        // Set default values
        gsap.set(sectionCardRef.current, { opacity: 0 })
        gsap.set(image, { opacity: 1 })
        gsap.set(textArray, { opacity: 0, y: '8px' })

        // Fade in the whole card
        inTl.current &&
          inTl.current
            .clear() // make sure the timeline is empty when matchMedia changes
            .to(sectionCardRef.current, {
              opacity: 1,
              duration: 1,
              ease: 'power1.out',
            })
            .to(sectionCardRef.current, {
              opacity: 0,
              duration: 1,
              ease: 'power1.out',
            })
        // Fade in the text
        activeTl.current &&
          activeTl.current
            .clear() // make sure the timeline is empty when matchMedia changes
            .addLabel('textStart')
            .to(
              textArray,
              {
                opacity: 1,
                y: 0,
                duration: 1,
                stagger: 0.2,
                ease: 'power1.out',
              },
              'start+=1'
            )
            .addLabel('textEnd')
            .to({}, { duration: 3 }) // empty tween to add a pause before the next animation starts
      },
    })

    // Landscape and Desktop animation
    ScrollTrigger.matchMedia({
      '(min-aspect-ratio: 1/1)': function () {
        // Set default values
        gsap.set(sectionCardRef.current, { opacity: 0 })
        gsap.set(image, { opacity: 1 })
        gsap.set(textArray, { opacity: 0, y: '8px' })

        // Fade in the whole card
        inTl.current &&
          inTl.current
            .clear() // make sure the timeline is empty when matchMedia changes
            .to(sectionCardRef.current, {
              opacity: 1,
              duration: 1,
              ease: 'power1.out',
            })
            .to(sectionCardRef.current, {
              opacity: 0,
              duration: 1,
              ease: 'power1.out',
            })
        // Dim the image again and fade in the text
        activeTl.current &&
          activeTl.current
            .clear() // make sure the timeline is empty when matchMedia changes
            .addLabel('start')
            .to(
              image,
              {
                opacity: 0.15,
                duration: 1,
                ease: 'power1.out',
              },
              'start+=1'
            )
            .addLabel('textStart')
            .to(
              textArray,
              {
                opacity: 1,
                y: 0,
                duration: 1,
                stagger: 0.2,
                ease: 'power1.out',
              },
              'start+=1'
            )
            .addLabel('textEnd')
            .to({}, { duration: 3 }) // empty tween to add a pause before the next animation starts
      },
    })

    return () => {
      inTl.current && inTl.current.kill()
      activeTl.current && activeTl.current.kill()
    }
    // I might need add something like const scrollMatchMedia = ScrollTrigger.matchMedia() as a dependency to update when the media query changes, but it seems to be working correctly
  }, [inTl, activeTl])

  const [thisTextPosition, setThisTextPosition] = useState(0)

  // Find the scroll position of the text where it has completely faded in. The dots on the right scroll to this position when clicked.
  useEffect(() => {
    // Refresh ScrollTrigger, otherwise activeTl.scrollTrigger.start will return 'undefined'
    ScrollTrigger.refresh()

    let percent
    let scrollPos

    if (activeTl.current) {
      percent =
        activeTl.current &&
        activeTl.current.labels.textEnd / activeTl.current.totalDuration() // percent value of textEnd divided by total animation
      scrollPos =
        activeTl.current.scrollTrigger.start +
        (activeTl.current.scrollTrigger.end -
          activeTl.current.scrollTrigger.start) *
          percent
      addToTextPositions(scrollPos)
      setThisTextPosition(scrollPos)
    }
    // eslint-disable-next-line
  }, [activeTl])

  // useEffect(() => {
  //   console.log(thisTextPosition)
  // }, [thisTextPosition])

  return (
    <li className={`${styles.sectionCardContainer}`}>
      <div ref={sectionCardRef} className={`${styles.sectionCard}`}>
        <div id={`${prefix}-text`} ref={textRef}>
          <div id={`${prefix}-image`} className={`${styles.imageWrapper}`}>
            <CustomImg localFile={sectionImage.localFile} alt={''} />
          </div>
          <div
            className={`srOnlyNoVisibleFocus noFocusOutline`}
            tabIndex="0"
            onFocus={() => {
              gsap.to(window, {
                duration: 1,
                scrollTo: { y: thisTextPosition },
                // scrollTo: `#${prefix}-title`,
              })
            }}
          >
            Scrolling to {sectionTitle}
          </div>
          <div className={`${styles.textContainer}`}>
            <p id={`${prefix}-date`} className={`h4 textColorWhite`}>
              {sectionDate}
            </p>
            <h3
              id={`${prefix}-title`}
              className={`${styles.sectionTitle} mb12`}
            >
              <Link
                to={`/${chapterSlug}/${sectionSlug}`}
                className={`textColorWhite`}
              >
                {sectionTitle}
              </Link>
            </h3>
            <div id={`${prefix}-description`}>
              <p className={`mb24 textColorWhite`}>{sectionDesc}</p>
              <Link
                to={`/${chapterSlug}/${sectionSlug}/`}
                className={`button`}
                style={{ display: 'inline-block' }}
              >
                <span className={`h5`}>Explore</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

SectionCard.propTypes = {
  sectionDate: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string.isRequired,
  sectionDesc: PropTypes.string.isRequired,
  sectionSlug: PropTypes.string.isRequired,
  chapterSlug: PropTypes.string.isRequired,
  sectionCode: PropTypes.string.isRequired,
  addToTextPositions: PropTypes.func.isRequired,
  setActiveDot: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}

// Using the second argument of memo() to stop unneeded renders https://reactjs.org/docs/react-api.html#reactmemo
// The props as a whole aren't equal, apparently because of cacheBusterProp, so I'm just comparing one value
function areEqual(prevProps, nextProps) {
  if (prevProps.sectionCode === nextProps.sectionCode) {
    return true
  } else {
    return false
  }
}

export default memo(SectionCard, areEqual)
